<template>
  <v-container fluid>
    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-row>
      <v-col cols="3" sm="3" md="3" id="driver">
        <v-card elevation="0" class="px-2 mx-auto">
          <v-card-title>Filter</v-card-title>
          <v-card-text>
            <insights-filter></insights-filter>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="9" sm="9" md="9">
        <v-card elevation="0" max-width="100%">
          <v-card-title>Results</v-card-title>

          <v-row v-if="!viewOnCampaignCreation" class="controller__options">
            <p>VIEW:</p>

            <v-btn
              v-for="btn in buttons"
              elevation="0"
              :key="btn.name"
              @click="setComponent(btn.cmp)"
              :class="{ active: btn.cmp === currentComponent }"
            >
              <v-icon color="black">{{ btn.icon }}</v-icon>
              {{ btn.name }}
            </v-btn>
          </v-row>

          <v-card-text>
            <v-row>
              <keep-alive>
                <component
                  :is="currentComponent"
                  :viewOnCampaignCreation="viewOnCampaignCreation"
                ></component>
              </keep-alive>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <alert></alert>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import InsightsFilter from "../../components/insights/InsightsFilter.vue";
import InsightsList from "../../components/insights/InsightsList.vue";
import InsightsMap from "../../components/insights/InsightsMap.vue";

export default {
  data() {
    return {
      billboards: [],
      currentComponent: "InsightsList",
      buttons: [
        { name: "Table", icon: "mdi-table-large", cmp: "InsightsList" },
        { name: "Map", icon: "mdi-map-legend", cmp: "InsightsMap" },
      ],

      loading: false,
      panel: [0, 1],

      filters: {
        lighting: [
          { item: "backlit", title: "Backlit" },
          { item: "frontlit", title: "Frontlit" },
          { item: "unlit", title: "Unlit" },
          { item: "led", title: "LED" },
        ],
        height: [
          { item: "eye level", title: "Eye Level" },
          { item: "moderate", title: "Moderate" },
          { item: "high", title: "High" },
        ],
        orientation: [
          { item: "landscape", title: "Landscape" },
          { item: "portrait", title: "Portrait" },
          { item: "square", title: "Square" },
        ],
        clutter: [
          { item: "solus", title: "Solus" },
          { item: "average", title: "Average" },
          { item: "cluttered", title: "Cluttered" },
        ],
        medium: [
          { item: "Wall Wrap", title: "Wall Wrap" },
          { item: "RoadArch/Gantry", title: "RoadArch/Gantry" },
          { item: "Rooftops", title: "Rooftops" },
          { item: "LED", title: "LED" },
          { item: "Unipole", title: "Unipole" },
          { item: "Medium", title: "Medium" },
          { item: "Bus Stop", title: "Bus Stop" },
          { item: "Bus Stop Shelter", title: "Bus Stop Shelter" },
        ],
        industry: [
          "Agriculture",
          "Forestry",
          "Fishing & Hunting",
          "Mining",
          "Utilities",
          "Construction",
          "Manufacturing",
          "Wholesale Trade",
          "Retail Trade",
          "Transportation and Warehousing",
          "Real Estate and Rental and Leasing",
          "Professional, Scientific and Technical Services",
          "Educational Services",
        ],
        side_of_road: ["Right", "Left", "Middle"],
      },

      pois: [
        "atm",
        "bank",
        "bar",
        "hospital",
        "kiosk",
        "market",
        "pharmacy",
        "police",
        "restaurant",
        "school",
        "supermarket",
        "university",
      ],

      searchQuery: {
        brand_name: [],
        supplier: [],
        lighting: [],
        site_run_up: 0,
        rate_card: { min: 0, max: 0 },
        height: [],
        length: 0,
        width: 0,
        orientation: [],
        clutter: [],
        medium: [],
        industry: [],
        side_of_road: [],
        faces: [],
        withinAddress: { lat: 0, lng: 0, radius: 1000 },
        nearPois: { pois: [], radius: 200 },
      },

      autoCompleteBounds: {
        bounds: {
          north: 11.234926,
          south: 4.737842,
          east: 1.201117,
          west: -3.265621,
        },
        strictBounds: true,
      },
    };
  },
  components: {
    InsightsList,
    InsightsMap,
    InsightsFilter,
  },

  computed: {
    ...mapGetters({
      getSearchResults: "billboards/getSearchResults",
    }),
  },

  props: {
    viewOnCampaignCreation: Boolean,
  },
  watch: {
    getSearchResults: {
      handler(val) {
        if (!val) return;
        this.billboards = this.getSearchResults;
      },
    },
  },

  methods: {
    setComponent(cmp) {
      this.currentComponent = cmp;
    },
  },
};
</script>

<style scoped>
.container {
  background: #fff;
}
#driver {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
}
.v-toolbar {
  font-size: 24px;
  margin: 0 !important;
}
.v-card__title {
  background-color: var(--v-secondary-lighten1);
  padding: 5px;
  color: black;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
}

.controller__options {
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.controller__options p {
  margin: 0;
  padding-right: 10px;
}
.controller__options > .v-btn {
  background: rgba(143, 146, 161, 0.05);
  border-radius: 32px;
  margin: 0 10px;
}

.active {
  background: #f68625 !important;
}
</style>
